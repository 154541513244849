<template>
  <div class="application-wrapper">
    <div class="application-nav">
      <div class="text">
        {{ $t('application') }}
      </div>
    </div>
    <div class="application-nav-link">
      <div>
        <router-link
          v-for="item in navLink"
          :key="item.prop"
          :to="item.link"
          tag="div"
          class="application-nav-link-router"
        >
          {{ $t(item.prop) }}
        </router-link>
      </div>
    </div>
    <div
      v-if="isLoad && applicationList.length > 0"
      class="router"
    >
      <router-view
        :list="applicationList"
      />
    </div>
  </div>
</template>

<script>
import { configLink } from '@/utils'
import { goGet } from '@/utils'
export default {
  name: 'application',
  metaInfo() {
    return {
      title: this.$t('application')
    }
  },
  data() {
    return {
      isLoad: false,
      navLink: [configLink[1], ...configLink[1].list],
      applicationList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      goGet('/file/list/web').then(res => {
        this.isLoad = true
        this.applicationList = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.application-wrapper {
  position: relative;
  width: 100%;
  .application-nav {
    width: 100%;
    height: 160px;
    background: url('~@/assets/image/application/nav.jpg') no-repeat;
    background-size: 100% 100%;
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  .application-nav-link {
    height: 50px;
    width: 100%;
    padding: 13px 16px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.8);
    position: sticky;
    top: 56px;
    background: #fff;
    z-index: 100;
    &>div {
      width: fit-content;
      display: flex;
      overflow: auto;
      .application-nav-link-router {
        font-size: 13px;
        color: #888888;
        line-height: 24px;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s;
        flex-shrink: 0;
        margin-right: 32px;
        &.router-link-exact-active, &:hover {
          transition: color 0.3s;
          color: rgba(34, 34, 34, 1);
        }
      }
    }
  }
}
</style>
